import React from "react"
import ZppPage from "src/components/zpp-page.jsx"

export default function SPMR(props) {
  return ZppPage({
    registrationCode: process.env.GATSBY_ZPP_REGISTRATION_CODE,
    priceId: process.env.GATSBY_SPMR_PRICE_ID,
    hideInProduction: false,
    title:
      "Nutze den App-basierten Onlinekurs Stressbewältigung durch Progressive Muskelrelaxation (SPMR) über deine Krankenkasse!",
    shortCourseName: "SPMR",
    longCourseName: "Stressbewältigung durch Progressive Muskelrelaxation",
    topImage: "/assets/img/spmr/otf_compressed.jpg",
    introduction: (
      <>
        In diesem Kurs lernst Du die Progressive Muskelrelaxation, kurz PMR,
        nach Edmund Jacobson kennen. Progressive Muskelrelaxation (PMR) ist ein
        Entspannungsverfahren, bei dem durch die bewusste An- und Entspannung
        bestimmter Muskelgruppen ein Zustand tiefer Ruhe und Entspannung
        erreicht werden kann. Über acht Module erfährst Du als Teilnehmer*in
        dieses Kurses, was Stress im eigentlichen Sinne bedeutet, wie und warum
        die PMR für die nötige Entspannung sorgt und auf welchem Wege sie Dir
        hilft, Stress nicht zum gesundheitlichen Risikofaktor werden zu lassen.
        Im Laufe des Kurses lernst Du sowohl die Langform der PMR, bei der 17
        Muskelgruppen angespannt werden, als auch die Kurzformen mit 7 bzw. 4
        Muskelgruppen kennen. <br />
        <br />
        <b>Bitte beachte, dass Dein Mindestalter 18 Jahre betragen muss.</b>
      </>
    ),
    howIsTheCourseStructured: (
      <>
        Der Kurs dauert insgesamt acht Wochen. Über diesen Zeitraum ist pro
        Woche ein Modul vorgesehen. Jedes Modul enthält ein Pflichtmodul und ein
        freiwilliges Zusatzmodul. Die einzelnen Module findest Du als
        Audiodateien in der Mindance App. Jedes Pflichtmodul dauert insgesamt
        ca. 60 Minuten und besteht aus drei Teilen (Informationsteil,
        praktischer Übungsteil mit PMR, Quizfragen & Zusammenfassung per
        E-Mail). Neben dem Pflichtmodul hast Du zwischen den wöchentlichen
        Modulen jeden Tag Zugang zu einer freiwilligen audiogeführten PMR über
        die Mindance App, die Du jedoch nicht durchführen musst, um Deine
        (Teil-)Rückerstattung zu erhalten.
      </>
    ),
    image1: "/assets/img/spmr/1.png",
    image2: "/assets/img/spmr/2.png",
  })
}
